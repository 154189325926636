<template>
  <!-- 添加或修改菜单对话框 -->
    <el-dialog :title="!form.menuId ? $t('button.add'): $t('button.update')" v-model="visible" append-to-body width="600">
        <div class="init">
            <el-form ref="dataForm" :model="form" :rules="rules" label-position="top">
                <el-form-item :label='$t("adminMenu.name1")' prop="menuType">
                    <el-radio-group v-model="form.menuType">
                    <el-radio-button label="0">{{$t("adminMenu.name5_1")}}</el-radio-button>
                    <el-radio-button label="1">{{$t("adminMenu.name5_3")}}</el-radio-button>
                    <!-- <el-radio-button label="2">顶菜单</el-radio-button> -->
                    </el-radio-group>
                </el-form-item>
                <el-form-item :label="$t('adminMenu.name9')" prop="parentId">
                    <el-tree-select v-model="form.parentId" :data="menuOptions" check-strictly :render-after-expand="false"
                        :placeholder="$t('inputTit.xuanze')"/>
                </el-form-item>
            <el-form-item :label="$t('adminMenu.name2')" prop="icon" v-if="form.menuType !== '1'">
                <avue-input-icon v-model="form.icon" :icon-list="iconList" ></avue-input-icon>
            </el-form-item>
            <el-form-item :label="$t('adminMenu.name1')" prop="name" v-if='form.menuType === "1"'>
                <el-input v-model="form.name" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('adminDict.name8')" prop="nameList" v-else>
                <el-input class="inputText" v-model="form.nameList[index].name" :placeholder="$t('inputTit.shuru')" v-for='(item,index) in showData' :key='item.label'>
                    <template #prepend>{{item.label}}</template>
                </el-input>
            </el-form-item>
            <el-form-item :label="$t('adminMenu.name4')" prop="path" v-if="form.menuType !== '1'">
                <el-input v-model="form.path" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            <el-form-item :label="$t('adminMenu.name7')" prop="permission" v-if="form.menuType === '1'">
                <el-input v-model="form.permission" :placeholder="$t('inputTit.shuru')" maxlength="50"/>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item :label="$t('adminMenu.name3')" prop="sortOrder">
                        <el-input-number v-model="form.sortOrder" controls-position="right" :min="0"/>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="12">
                    <el-form-item label="缓存" prop="keepAlive">
                        <el-radio-group v-model="form.keepAlive">
                            <el-radio-button label="1">是</el-radio-button>
                            <el-radio-button label="0">否</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col> -->
            </el-row>
            </el-form>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="visible = false">{{$t('button.close')}}</el-button>
                <el-button type="primary" @click="dataFormSubmit(dataForm)">{{$t('button.enter')}}</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref,reactive,defineExpose,defineEmits,getCurrentInstance } from 'vue'
import iconList from '@/const/iconList'
import Api_ from '@/api/index'
// import Treeselect from 'vue3-treeselect'
// import 'vue3-treeselect/dist/vue3-treeselect.css'
import { ElNotification } from 'element-plus'
// import parms from '@/api/admin/parms';
const { $t } = getCurrentInstance().proxy;
import website from '@/config/website'
import { isHaveParm  } from '@/utils/dataBind/parmsView'

const rules = ref(null)
const visible = ref(false)

rules.value = {
    name: [
        {required: true, message: $t('inputTit.shuru'), trigger: "blur"}
    ],
    sortOrder: [
        {required: true, message: $t('inputTit.shuru'), trigger: "blur"}
    ],
    path: [
        {required: true, message: $t('inputTit.shuru'), trigger: "blur"}
    ],
    keepAlive: [
        {required: true, message: $t('inputTit.shuru'), trigger: "blur"}
    ],
    permission: [
        {required: true, message: $t('inputTit.shuru'), trigger: "blur"}
    ]
}
const menuOptions = ref([])
const dataForm = ref(null)
const showData = ref([])
const bindData = ()=>{
    isHaveParm(2,(data)=>{
        let showData_ = [];let parmData_ = [];
        for(let i = 0;i < data.length;++ i){
            showData_.push({
                value: data[i].value,
                label: data[i].label,
            })
            parmData_.push({
                language: data[i].value,
                name: '',
            })
        }
        showData.value = showData_
        form.nameList = parmData_
    })
}

const form = reactive({
    id: 0,
    name: '',
    path: '',
    icon: '',
    permission: '',
    menuType: '0',
    keepAlive: '0',
    sortOrder: 999,
    parentId: '-1',
    tenantId: '',
    nameList: []
})
const emit = defineEmits(['refreshDataList'])

const dataFormSubmit = (dataForm)=>{
    if (!dataForm) return
    dataForm.validate((valid) => {
        if (valid) {
            form.tenantId = sessionStorage.getItem(website.key+'-tenantId')
            if(form.menuType !== '1'){
                form.name = form.nameList[0].name
            }else{
                form.nameList = undefined
            }
            if (form.id) {
                form['menuId'] = form.id;
                Api_.updateMenu(form).then(() => {
                    ElNotification({
                        message: $t('alert.succUpdate'),
                        type: 'success',
                    })
                    visible.value = false
                    emit('refreshDataList')
                });
            } else {
                Api_.addMenu(form).then(() => {
                    ElNotification({
                        message: $t('alert.succAdd'),
                        type: 'success',
                    })
                    visible.value = false
                    emit('refreshDataList')
                })
            }
        }
    })
}
const getDatalist = (obj_)=>{
    let obj_1 = {}
    for(let index in obj_){
        if(index === 'id'){
            obj_1.value = obj_[index]
        }
        if(index === 'name'){
            obj_1.label = obj_[index]
        }
        if(index === 'children'){
            obj_1.children = getDatalistArray(obj_[index])
        }
    }
    return obj_1
}
const getDatalistArray = (st_)=>{
    let array_ = []
    for(let i = 0;i < st_.length;++ i){
        array_[i] = getDatalist(st_[i])
    }
    return array_
}
const getTreeselect = ()=>{
    Api_.fetchMenuTree().then(response => {
        let array_ = {id: '-1', name: '根菜单', children: response.data};
        menuOptions.value = [getDatalist(array_)]
    });
}
const init = (isEdit, item)=>{
    visible.value = true
    removeDate()
    bindData()
    if (isEdit) {
        Object.keys(form).forEach(key => {
            if(key !== 'nameList' && key !== 'tenantId'){
                if(item[key]){
                    form[key] = item[key]
                }
            }
        })         
        if(form.menuType !== '1'){
            Api_.getMenuListDetail({
                menuId: form.id,
            }).then(res=>{
                for(let item of form.nameList){//应该以字典查到的语种为判断唯一的依据
                    for(var i = 0 ; i < res.data.records.length ; i++){
                        if(item.language == res.data.records[i].language){
                            item.name = res.data.records[i].name
                            item.nameId = res.data.records[i].nameId
                            break;
                        }
                    }
                }
            })
        }                                                                             
    } else {
        if (item !== 1) {//修改
            form.parentId = item.id;
        }
    }
}
const removeDate = ()=>{//清除数据
    Object.keys(form).forEach(key => {
        if(key && key !== 'tenantId'){
            form[key] = ''
        }
    }) 
    form.menuType = '0'
    form.keepAlive = '0'
    form.parentId = '-1'
    form.sortOrder = 999
}
getTreeselect()
defineExpose({
    init
})
</script>
<style lang="scss" scoped>
    .inputText{
        margin-bottom: 10px;
    }
</style>
